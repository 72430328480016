$base-colors: (
  dark: $color-web-black,
  medium: $color-grey-500,
  light: $color-web-white,
  accent: map-get($settings, accent)
);

$theme-dark: (
  menu-background: map-get($base-colors, dark),
  menu-text: map-get($base-colors, light),
  normal: map-get($base-colors, medium)
);

$theme-light: (
  menu-background: hsl(0, 0%, 100%),
  menu-text: map-get($base-colors, medium),
  normal: map-get($base-colors, medium)
);

$theme: $theme-light !default;

@if (map-get($settings, theme) == 'dark') {
  $theme: $theme-dark;
}

$colors: map-merge($base-colors, $theme);

$menu-width: map-get($settings, menu-width);


// functions
@function color($color) {
    @return map-get($colors, $color);
}


// mixins
@mixin clearfix {
  &:after {
    clear: both;
  }

  &:before,
  &:after {
    display: table;
    content: ' ';
  }
}

@mixin border-box {
  box-sizing: border-box;
}
