html {
  height: 100%;

  &.f-menu-active {
    overflow: hidden;

    @media (min-width: 60em) {
      overflow: auto;
    }
  }
}

body {
  margin: 0;
  position: relative;
  min-height: 100vh;
  
  .f-menu-active & {
    width:100%;
    height: 100%;
    overflow: hidden;

    @media (min-width: 60em) {
      overflow: auto;
      height: auto;
    }
  }
}

.f-container {
  @include clearfix;
  @include border-box;
  position: relative;
  z-index: 0;
  min-height: 100vh;

  @media (min-width: 60em) {
    padding-top: 5rem;
  }

  .f-menu-active & {
    transform: translate($menu-width, 0);

    @media (min-width: 60em) {
      transform: translate(0, 0);
    }
  }

}

.f-menu-active body {
  padding-left: $menu-width;
}

.f-hero-section {
  background-image: url(../../../assets/toolkit/images/hero-hand1.jpg);
}