.f-color-chips {
  display: flex;
  flex-wrap: wrap;
}

.f-color-chip {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  border-top-width: 8rem;
  border-top-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  background-color: #fff;
  font-weight: 700;
  font-size: 1rem;
  padding: 1rem;
  margin-bottom: 2rem;
  box-sizing: border-box;

  @media (min-width: 60em) {
    flex-basis: 13rem;
  }

  .f-color-chip__variable {
    font-weight: 400;
  }

  .f-color-chip__color {
    content: attr(data-color);
    font-weight: 400;
    color: #999;
  }
}
