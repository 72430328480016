.f-item-group {
  @include clearfix;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  @media (min-width: 60em) {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  ~ .f-item-group {
    margin-top: 0;
  }

  > .f-item-group {
    margin-top: 0;
    padding-bottom: 0;

    border-bottom: 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.f-item-code {
  padding: 1rem;

  position: relative;
  z-index: 1;

  background-color: $color-grey-100;
  outline: 1px solid $color-grey-100;
  pre,
  code {
    margin-bottom: 0;
  }
}

.f-item-preview {
  @include clearfix;

  padding: 2rem;
  margin-top: 1rem;

  position: relative;
  z-index: 2;

  background-color: white;
  outline: 1px solid $color-grey-100;
}
  .f-item-preview-dark {
    background-color: $alt-color-lighter;
    border-color: $color-web-black;
    border-bottom-color: darken($color-web-black, 5%);
  }

.f-item-border-bottom {
  border-bottom: 1px solid map-get($colors, light);
}

.f-item-heading-group {
  @include clearfix;
  margin-bottom: 1rem;
  vertical-align: middle;
}

.f-item-heading {
  
  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      &::after {
        content: ' #';
        color: $color-grey-500;
      }
    }
  }
}

.f-item-controls {
  margin-top: 1rem;

  .f-control {
    vertical-align: baseline;

    color: map-get($colors, medium);

    padding: 0.5rem 0;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0.25rem;

      vertical-align: text-bottom;
    }

    use {
      fill: map-get($colors, medium);
    }

    &:hover {
      color: map-get($colors, dark);

      use {
        fill: map-get($colors, dark);
      }
    }
  }
}

.f-item-hidden {
  display: none;
}
