.b-organization {
  .b-color, .b-color:before, .b-color:after, .b-color-before:before, .b-color-after:after {
    color: #e30031;
  }
  .b-background {
    background-color: #e30031;
  }
  .b-border {
    border-color: #e30031;
  }
  .b-fill {
    fill: #e30031;
  }
}


.section--hero {
  .b-group & {
    background-image: url(/assets/toolkit/images/hero-hand1.jpg);
    background-image: url(/assets/toolkit/images/hero-hand2.jpg);
    background-image: url(/assets/toolkit/images/hero-hand-3.jpg);
  }
  .b-organization & {
    background-image: url(/assets/toolkit/images/hero-jowa-4.jpg);
    background-image: url(/assets/toolkit/images/hero-migrol.jpg);
  }
}