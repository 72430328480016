// menu
.f-menu {
  @include border-box;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-$menu-width, 0);
  width: $menu-width;
  height: 100%;
  z-index: 9000;
  background-color: $color-grey-100;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .f-menu-active & {
    transform: translate(0, 0);
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  > ul {
    margin-top: 0;
    margin-bottom: 1rem;

    > li {
      margin-top: 1rem;
    }
  }

  li {
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    display: block;
    padding: 0 2rem;
    color: color(menu-text);
    text-decoration: none;
    font-size: 14px;
    line-height: 3rem;

    &:hover {
      color: color(menu-text);
      text-decoration: underline;
    }

    &.f-active {
      &, &:hover {
        box-shadow: inset 0.25rem 0 0 0 currentColor;
        color: $color-web-black;
      }
    }
  }

  .f-menu__heading {
    padding-left: 1.5rem;
    font-weight: 700;

    &:hover {
      color: color(normal);
    }
  }
}
