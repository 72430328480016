.f-controls {
  @include clearfix;
  @include border-box;

  .f-control {
    display: block;
    float: left;
    text-align: center;
    width: percentage(1/5);
    margin: 0;
    padding: 1rem 0;
    font-size: 11px;
    font-weight: bold;
    opacity: 0.3;
    &.f-active {
      box-shadow: inset 0 0.25rem 0 0 currentColor;

      opacity: 1;
    }

    svg {
      vertical-align: middle;
    }
  }

  .f-brand-control {
    opacity: 1;
    box-shadow: none !important;
  }

  .f-icon-brand {
    background: currentColor;
    border-radius: 100%;
    margin: 1rem auto;
  }
}

.f-control {
  @include border-box;
  display: inline-block;
  cursor: pointer;
  margin-left: 0.66rem;

  &:first-child {
    margin-left: 0;
  }

  svg,.f-icon-brand {
    width: 1.5rem;
    height: 1.5rem;

    use {
      fill: $text-color;
    }
  }
}

.f-control-bar {
  @include clearfix;
  height: 5rem;
  display: flex;
  align-items: center;
}

.f-menu-toggle {
  cursor: pointer;
  vertical-align: middle;
  padding-left: 1rem;
  
  svg {
    display: block;
    float: left;
    margin-bottom: -1px;
  }
}