/**
 * Fabricator styles
 * @author Luke Askew
 * Class selectors are namespaced with "f-"
 */

@import '../../toolkit/styles/settings/settings';
@import '../../toolkit/styles/settings/kanbasu';

$settings: (
	theme: light,
	accent: $color-mint,
	menu-width: 24rem
);

@import 'partials/variables';
@import 'partials/color-chips';
@import 'partials/controls';
@import 'partials/item';
@import 'partials/layout';
@import 'partials/menu';
@import 'partials/brand';
